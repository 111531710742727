import { useTheme } from "@mui/material/styles";
import { Alert, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";

import fetchUser from "src/utils/fetchUser";
import { setSession } from "src/utils/jwt";
import Iconify from "./Iconify";

const ImpersonationBanner = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { user } = useAuth();
  const isImpersonate = localStorage.getItem("isImpersonate");
  const goBackToAdmin = async () => {
    const params = {
      sub_admin_impersonate: localStorage.getItem("source_id") || null,
    };

    try {
      const {
        status,
        data: { access_token, menu_list },
      } = await fetchUser.get("back-to-admin", { params });
      if (status === 200) {
        localStorage.removeItem("profile");
        localStorage.setItem("isAdmin", true);
        localStorage.setItem("menu", JSON.stringify(menu_list));
        localStorage.removeItem("isSubAdmin");
        localStorage.removeItem("isImpersonate");
        setSession(access_token);
        window.location = `${window.origin}${sessionStorage.getItem(
          "impersonationSource"
        )}`;
      }
    } catch (err) {
      console.error(err);
    }
  };
  return isImpersonate ? (
    <Box mb={2}>
      <Alert
        sx={{
          backgroundColor: "rgba(254, 98, 0, 0.08)",
          color: "#3f51b5",
          alignItems: "center",
        }}
        icon={
          <>
            <Iconify
              icon="fluent:table-switch-16-filled"
              style={{ color: "#fe6200" }}
            />
          </>
        }
      >
        {t("impersonate.heads")} {user.username}
        <Button
          onClick={goBackToAdmin}
          size="small"
          variant="text"
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {t("impersonate.click")}
        </Button>
        {t("impersonate.toGo")}
      </Alert>
    </Box>
  ) : null;
};

export default ImpersonationBanner;
